<template>
  <div class="component-wrap">
    <div class="component-tit">
      <RollbackOutlined class="back" @click="back" />{{ $t("bbs.my.draft") }}
      <!-- 我的草稿 -->
    </div>
    <div class="content">
      <a-spin :spinning="loading">
        <div
          class="list"
          v-infinite-scroll="handleInfiniteOnLoad"
          :infinite-scroll-immediate-check="false"
          :infinite-scroll-disabled="scrollDisabled"
          infinite-scroll-watch-disabled="scrollDisabled"
          :infinite-scroll-distance="50"
        >
          <div
            class="list-item"
            v-for="item in subjectList"
            :key="item.subjectId"
          >
            <posts
              model="mine-draft"
              :newWindow="newWindow"
              :data="item"
              @previewVideo="(url) => $refs.previewVideoRef.openModal(url)"
              @postsEdit="$refs.modalArticleRef.openModal(item)"
              @postsSubmit="postsSubmit(item)"
            />
          </div>
          <a-empty
            v-if="!subjectList.length && !loading"
            style="padding: 50px 0"
          />
        </div>
      </a-spin>
      <div class="load-more" v-if="subjectList.length && !loading">
        {{ scrollDisabled ? $t("bbs.no_more") : $t("LB_Doc_Mobile_Load") }}
        <!-- "已经到底了" : "加载中..." -->
      </div>
    </div>
  </div>
  <previewVideo ref="previewVideoRef" />
  <modalArticle
    ref="modalArticleRef"
    :communityConfig="communityConfig"
    @handleOk="postEnd()"
  />
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, computed, nextTick, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { getSubjectList, getCommunityConfig, publishSubject } from "@/api/bbs";

import posts from "../../components/posts.vue";
import previewVideo from "../../components/previewVideo.vue";
import modalArticle from "../../components/modalArticle.vue";

export default {
  components: {
    posts,
    previewVideo,
    modalArticle,
  },
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      newWindow: computed(() => store.getters.companyInfo.useNewWindow == 1),
      communityConfig: {},
      loading: false,
      scrollDisabled: true,
      subjectParams: {
        ownNotPublish: 1,
        pageSize: 10,
        page: 1,
        order: 1,
        subjectType: 0,
      },
      subjectList: [],
    });

    getCommunityConfig().then((res) => {
      state.communityConfig = res.data;
    });

    const getSubject = () => {
      if (state.subjectParams.page === 1) {
        state.loading = true;
      }
      state.scrollDisabled = true;
      getSubjectList(state.subjectParams).then((res) => {
        state.loading = false;
        let list = res.data.list || [];
        state.subjectList.push(...list);
        if (state.subjectList.length < res.data.totals) {
          nextTick(() => {
            state.scrollDisabled = false;
          });
        } else {
          state.scrollDisabled = true;
        }
      });
    };
    getSubject();

    const handleInfiniteOnLoad = () => {
      if (!state.scrollDisabled) {
        state.subjectParams.page++;
        getSubject();
      }
    };

    const postEnd = () => {
      state.subjectParams.page = 1;
      state.subjectList = [];
      getSubject();
    };

    const postsSubmit = (data) => {
      publishSubject({
        id: data.subjectId,
      }).then((res) => {
        if (res.ret === 0) {
          postEnd();
          proxy.$message.success($t("CM_Success"));
          // 操作成功
        }
      });
    };

    const back = () => {
      if (state.newWindow) {
        window.location.href = "/bbs";
      } else {
        router.push("/bbs");
      }
    };

    return {
      ...toRefs(state),
      handleInfiniteOnLoad,
      postEnd,
      postsSubmit,
      back,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  min-height: 100%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  .component-tit {
    font-size: 20px;
    line-height: 54px;
    color: #202020;
    padding: 0 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    .back {
      margin-right: 10px;
      color: #999;
      cursor: pointer;
    }
  }
  .content {
    padding: 0 30px;
    background-color: #fff;
    border-radius: 2px;
    .list {
      min-height: 200px;
      &-item {
        padding: 30px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      }
    }
    .load-more {
      text-align: center;
      font-size: 14px;
      color: #999;
      line-height: 20px;
      padding: 24px 0;
    }
  }
}
</style>
