<template>
  <section class="mine-wrap">
    <div class="nav">
      <div class="nav-wrap">
        <ul class="menu">
          <div class="menu-title">{{ $t('cm_bbs') }}</div>
          <!-- 学习社区 -->
          <template v-for="item in menus" :key="item.key">
            <li
              :class="{ active: currentView === item.key }"
              @click="clickJump(item)"
            >
              {{ item.name }}
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div class="content">
      <component :is="currentView" @emitJump="clickJump"></component>
    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n';
const files = require.context("./components", false, /\.vue$/);
const components = {};
files.keys().forEach((key) => {
  components[key.replace(/(\.\/|\.vue)/g, "")] = files(key).default;
});
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  components,
  setup() {
    const { t: $t } = useI18n();
    const route = useRoute();
    const menus = ref([
      {
        name: $t('bbs.my.publish'),
        // 我发布的
        key: "subject",
      },
      {
        name: $t('bbs.my.draft'),
        // 我的草稿
        key: "draft",
      },
      {
        name: $t('bbs.my.follow'),
        // 我关注的
        key: "follow",
      },
      {
        name: $t('bbs.my.collect'),
        // 我收藏的
        key: "collection",
      },
      {
        name: $t('bbs.my.comment'),
        // 评论我的
        key: "comment",
      },
      {
        name: $t('bbs.my.answer'),
        // 回答我的
        key: "answer",
      },
      {
        name: $t('bbs.my.like'),
        // 点赞我的
        key: "like",
      },
      {
        name: $t('bbs.my.invited'),
        // 邀请我的
        key: "invitation",
      },
    ]);
    const currentView = ref(menus.value[0].key);

    const setCurrentView = (key) => {
      let routeCurrent = key;
      menus.value.map((item) => {
        if (routeCurrent == item.key) currentView.value = item.key;
      });
    };

    const clickJump = (item) => {
      location.hash = item.key;
    };

    watch(
      () => route.hash,
      (val) => {
        let key = val.slice(1);
        setCurrentView(key);
      },
      { immediate: true, deep: true }
    );

    return {
      menus,
      currentView,
      clickJump,
    };
  },
};
</script>

<style lang="less" scoped>
.mine-wrap {
  .mixinWrap();
  .mixinFlex(space-between);
  padding: 32px 0 40px;
}

.nav {
  width: 150px;
  .nav-wrap {
    padding-bottom: 12px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.06);
    background-color: #fff;
    .menu {
      user-select: none;
      &-title {
        font-size: 20px;
        color: #202020;
        line-height: 54px;
        text-align: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        margin-bottom: 12px;
      }
      li {
        padding: 12px 0;
        text-align: center;
        line-height: 24px;
        font-size: 16px;
        cursor: pointer;
        &:hover,
        &.active {
          color: @color-theme;
        }
      }
    }
  }
}

.content {
  width: 1026px;
}
</style>
