<template>
  <a-modal
    v-model:visible="visible"
    @cancel="hideDialog"
    title="图片上传"
    :width="width + 48"
  >
    <vuePictureCropper
      :boxStyle="{
        width: width + 'px',
        height: height + 'px',
        backgroundColor: '#f8f8f8',
        margin: 'auto',
      }"
      :img="dataUrl"
      :options="{
        viewMode: 1,
        dragMode: 'move',
        aspectRatio: width / height,
        cropBoxResizable: false,
      }"
      :presetMode="{
        mode: 'fixedSize',
        width: width,
        height: height,
      }"
    />
    <template #footer>
      <a-button @click="hideDialog">取消</a-button>
      <a-button type="primary" :loading="loading" @click="upLoad"
        >上传</a-button
      >
    </template>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRefs, getCurrentInstance } from "vue";
import { uploadUrl } from "@/utils/tools";
import vuePictureCropper, { cropper } from "vue-picture-cropper";
import request from "@/utils/request";

export default defineComponent({
  components: { vuePictureCropper },
  props: {
    requestUrl: {
      type: String,
      default: "study/upload/file",
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    multiple: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance();
    const state = reactive({
      visible: false,
      loading: false,
      dataUrl: "",
    });

    const showDialog = (dataUrl) => {
      state.visible = true;
      state.dataUrl = dataUrl;
    };

    const hideDialog = () => {
      state.visible = false;
    };

    const upLoad = async () => {
      state.loading = true;
      const blob = await cropper.getBlob();
      const file = await cropper.getFile();
      let formData = new FormData();
      formData.append("file", blob, file.name);
      request.post(uploadUrl + props.requestUrl, formData).then((res) => {
        state.loading = false;
        if (res.ret == 0) {
          proxy.$message.success("文件上传成功");
          hideDialog();
          emit("cropper:url", res.data);
        } else {
          proxy.$message.error(res.msg || "文件上传失败");
        }
      });
    };

    return {
      ...toRefs(state),
      showDialog,
      hideDialog,
      upLoad,
    };
  },
});
</script>
